import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: { name: 'Login' },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () =>
      import(/* webpackChunkName: "Admin" */ '../views/admin/Index.vue'),
    children: [
      {
        path: '/admin',
        redirect: { name: 'EnterpriseList' },
      },
      {
        path: '/enterprise/list',
        name: 'EnterpriseList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "EnterpriseList" */ '../views/admin/enterprise/EnterpriseList.vue')
      },
      {
        path: '/enterprise/add',
        name: 'EnterpriseAdd',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "EnterpriseEditor" */ '../views/admin/enterprise/EnterpriseEditor.vue')
      },
      {
        path: '/enterprise/edit/:id',
        name: 'EnterpriseEdit',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "EnterpriseEditor" */ '../views/admin/enterprise/EnterpriseEditor.vue')
      },

      {
        path: '/brand/list',
        name: 'BrandList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "BrandList" */ '../views/admin/brand/BrandList.vue')
      },
      {
        path: '/brand/add',
        name: 'BrandAdd',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "BrandEditor" */ '../views/admin/brand/BrandEditor.vue')
      },
      {
        path: '/brand/edit/:id',
        name: 'BrandEdit',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "BrandEditor" */ '../views/admin/brand/BrandEditor.vue')
      },
      {
        path: '/store/list',
        name: 'StoreList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "StoreList" */ '../views/admin/store/StoreList.vue')
      },
      {
        path: '/store/add',
        name: 'StoreAdd',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "StoreEditor" */ '../views/admin/store/StoreEditor.vue')
      },
      {
        path: '/store/edit/:id',
        name: 'StoreEdit',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "StoreEditor" */ '../views/admin/store/StoreEditor.vue')
      },
      {
        path: '/coupon/list',
        name: 'CouponList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "CouponList" */ '../views/admin/coupon/CouponList.vue')
      },
      {
        path: '/coupon/add',
        name: 'CouponAdd',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "CouponEditor" */ '../views/admin/coupon/CouponEditor.vue')
      },
      {
        path: '/coupon/edit/:id',
        name: 'CouponEdit',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "CouponEditor" */ '../views/admin/coupon/CouponEditor.vue')
      },
      {
        path: '/target/list',
        name: 'TargetList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "TargetList" */ '../views/admin/target/TargetList.vue')
      },
      {
        path: '/target/add',
        name: 'TargetAdd',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "TargetEditor" */ '../views/admin/target/TargetEditor.vue')
      },
      {
        path: '/target/edit/:id',
        name: 'TargetEdit',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "TargetEditor" */ '../views/admin/target/TargetEditor.vue')
      },
      // event
      {
        path: '/event/list',
        name: 'EventList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "EventList" */ '../views/admin/event/EventList.vue')
      },
      {
        path: '/event/add',
        name: 'EventAdd',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "EventEditor" */ '../views/admin/event/EventEditor.vue')
      },
      {
        path: '/event/edit/:id',
        name: 'EventEdit',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "EventEditor" */ '../views/admin/event/EventEditor.vue')
      },

      {
        path: '/faq/list',
        name: 'FaqList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "FaqList" */ '../views/admin/faq/FaqList.vue')
      },
      {
        path: '/faq/add',
        name: 'FaqAdd',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "FaqEditor" */ '../views/admin/faq/FaqEditor.vue')
      },
      {
        path: '/faq/edit/:id',
        name: 'FaqEdit',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "FaqEditor" */ '../views/admin/faq/FaqEditor.vue')
      },
      {
        path: '/user/list',
        name: 'UserList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "UserList" */ '../views/admin/user/UserList.vue')
      },
      {
        path: '/user/apply/:id',
        name: 'UserApply',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "UserApply" */ '../views/admin/user/UserApply.vue')
      },
      {
        path: '/admin/edit',
        name: 'AdminEdit',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "AdminEdit" */ '../views/admin/admin/AdminEdit.vue')
      },
      {
        path: '/usercoupon/list',
        name: 'UserCouponList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "UserCouponList" */ '../views/admin/usercoupon/UserCouponList.vue')
      },
      {
        path: '/couponusehistory/list',
        name: 'CouponUseHistoryList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "CouponUseHistoryList" */ '../views/admin/couponUseHistory/CouponUseHistoryList.vue')
      },
      {
        path: '/feedback/list',
        name: 'FeedbackList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "FaqList" */ '../views/admin/feedback/FeedbackList.vue')
      },
      {
        path: '/feedback/edit/:id',
        name: 'FeedbackEdit',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "FaqEditor" */ '../views/admin/feedback/FeedbackEditor.vue')
      },
      {
        path: '/point/list',
        name: 'PointList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "PointList" */ '../views/admin/point/PointList.vue')
      },
      {
        path: '/point/add',
        name: 'PointAdd',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "PointEditor" */ '../views/admin/point/PointEditor.vue')
      },
      {
        path: '/point/detail/:id',
        name: 'PointDetail',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "PointDetail" */ '../views/admin/point/PointDetail.vue')
      },
      // NewbieDistributeList
      {
        path: '/newbie-distribute/list',
        name: 'NewbieDistributeList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "NewbieDistributeList" */ '../views/admin/newbieDistribute/NewbieDistributeList.vue')
      },
      {
        path: '/newbie-distribute/add',
        name: 'NewbieDistributeAdd',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "NewbieDistributeEditor" */ '../views/admin/newbieDistribute/NewbieDistributeEditor.vue')
      },
      {
        path: '/newbie-distribute/edit/:id',
        name: 'NewbieDistributeEdit',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "NewbieDistributeEditor" */ '../views/admin/newbieDistribute/NewbieDistributeEditor.vue')
      },
      // NewbieTargetList
      {
        path: '/newbie-target/list/:id',
        name: 'NewbieTargetList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "NewbieTargetList" */ '../views/admin/newbieTarget/NewbieTargetList.vue')
      },
      {
        path: '/newbie-target/add/:newbieDistributeId',
        name: 'NewbieTargetAdd',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "NewbieTargetEditor" */ '../views/admin/newbieTarget/NewbieTargetEditor.vue')
      },
      {
        path: '/newbie-target/edit/:newbieDistributeId/:id',
        name: 'NewbieTargetEdit',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "NewbieTargetEditor" */ '../views/admin/newbieTarget/NewbieTargetEditor.vue')
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "Dashboard" */ '../views/admin/dashboard/Dashboard.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const shouldLogin = to.meta.shouldLogin
  if (shouldLogin) {
    // 如果是需要登录的页面，在判断前记录下路由信息并存储到seesionStorage中
    const newRoutObj = {
      name: to.name,
      params: to.params,
      query: to.query,
    }

    sessionStorage.setItem('lastRoute', JSON.stringify(newRoutObj))
  }
  next()
})

export default router
