export default function (api) {
  return {
    login (data) {
      return api.post('/login', data)
    },
    logout () {
      return api.post('/logout')
    },
    // barand
    getBrand (data) {
      return api.get(`/brand`, {
        params: data
      });
    },
    getBrandDetail (id) {
      return api.get(`/brand/${id}`);
    },
    postBrand (data) {
      return api.post(`/brand`, data)
    },
    putBrand (data, id) {
      return api.put(`/brand/${id}`, data)
    },
    deleteBrand (id) {
      return api.delete(`/brand/${id}`);
    },
    // store
    getStore (data) {
      return api.get(`/store`, {
        params: data
      });
    },
    getStoreDetail (id) {
      return api.get(`/store/${id}`);
    },
    postStore (data) {
      return api.post(`/store`, data)
    },
    putStore (data, id) {
      return api.put(`/store/${id}`, data)
    },
    deleteStore (id) {
      return api.delete(`/store/${id}`);
    },
    // coupon
    getCoupon (data) {
      return api.get(`/coupon`, {
        params: data
      });
    },
    getCouponDetail (id) {
      return api.get(`/coupon/${id}`);
    },
    postCoupon (data) {
      return api.post(`/coupon`, data)
    },
    putCoupon (data, id) {
      return api.put(`/coupon/${id}`, data)
    },
    deleteCoupon (id) {
      return api.delete(`/coupon/${id}`);
    },
    // taget
    getTarget (data) {
      return api.get(`/target`, {
        params: data
      });
    },
    getTargetDetail (id) {
      return api.get(`/target/${id}`);
    },
    postTarget (data) {
      return api.post(`/target`, data)
    },
    putTarget (data, id) {
      return api.put(`/target/${id}`, data)
    },
    deleteTarget (id) {
      return api.delete(`/target/${id}`);
    },
    // get /target/newbie
    getTargetNewbie (data) {
      return api.get(`/target/newbie`, {
        params: data
      });
    },
    // faq
    getFaq (data) {
      return api.get(`/faq`, {
        params: data
      });
    },
    getFaqDetail (id) {
      return api.get(`/faq/${id}`);
    },
    postFaq (data) {
      return api.post(`/faq`, data)
    },
    putFaq (data, id) {
      return api.put(`/faq/${id}`, data)
    },
    deleteFaq (id) {
      return api.delete(`/faq/${id}`);
    },
    // user
    getUser (data) {
      return api.get(`/user`, {
        params: data
      });
    },
    getUserDetail (id) {
      return api.get(`/user/${id}`);
    },
    putUser (data, id) {
      return api.put(`/user/${id}`, data)
    },

    // common
    getCommonUpoad (code) {
      return api.get(`/common/upload/${code}`);
    },
    getCommonCosUrl () {
      return api.get(`/common/cosurl`);
    },
    getCommonIndustrytype () {
      return api.get(`/common/industrytype`);
    },
    getCommonUserextendinfo () {
      return api.get(`/common/userextendinfo`);
    },
    getCommonStoretags () {
      return api.get(`/common/storetags`);
    },
    getCommonStoreinfotypes () {
      return api.get(`/common/storeinfotypes`);
    },
    // enterprise
    getEnterprise (data) {
      return api.get(`/enterprise`, {
        params: data
      });
    },
    deleteEnterprise (id) {
      return api.delete(`/enterprise/${id}`);
    },
    getEnterpriseDetail (id) {
      return api.get(`/enterprise/${id}`);
    },
    postEnterprise (data) {
      return api.post(`/enterprise`, data)
    },
    putEnterprise (data, id) {
      return api.put(`/enterprise/${id}`, data)
    },
    getEnterpriseQRCode (id) {
      return api.get(`/enterprise/${id}/qrcode`);
    },
    // event
    getEvent (data) {
      return api.get(`/event`, {
        params: data
      });
    },
    deleteEvent (id) {
      return api.delete(`/event/${id}`);
    },
    getEventDetail (id) {
      return api.get(`/event/${id}`);
    },
    postEvent (data) {
      return api.post(`/event`, data)
    },
    putEvent (data, id) {
      return api.put(`/event/${id}`, data)
    },
    // admin
    getAdmin () {
      return api.get(`/admin`);
    },
    putAdmin (data) {
      return api.put(`/admin`, data);
    },
    // usercoupon
    getCouponusehistory (data) {
      return api.get(`/couponusehistory`, {
        params: data
      });
    },
    getUserCoupon (data) {
      return api.get(`/usercoupon`, {
        params: data
      });
    },
    // feedback
    getFeedback (data) {
      return api.get(`/feedback`, {
        params: data
      });
    },
    getFeedbackDetail (id) {
      return api.get(`/feedback/${id}`);
    },
    putFeedback (data, id) {
      return api.put(`/feedback/${id}`, data)
    },
    deleteFeedback (id) {
      return api.delete(`/feedback/${id}`);
    },
    // point
    getPoint (data) {
      return api.get(`/point`, {
        params: data
      });
    },
    getPointDetail (id) {
      return api.get(`/point/${id}`);
    },
    postPoint (data) {
      return api.post(`/point`, data)
    },
    getPointUserCount (data) {
      return api.get(`/point/usercount`, {
        params: data
      });
    },
    getPointUser (id, data) {
      return api.get(`/point/${id}/user`,
        {
          params: data
        });
    },
    // /newbie/distribute
    getNewbieDistribute (data) {
      return api.get(`/newbie/distribute`, {
        params: data
      });
    },
    // get /newbie/distribute/{id}
    getNewbieDistributeDetail (id) {
      return api.get(`/newbie/distribute/${id}`);
    },
    // delete /newbie/distribute/{id}
    deleteNewbieDistribute (id) {
      return api.delete(`/newbie/distribute/${id}`);
    },
    // post /newbie/distribute
    postNewbieDistribute (data) {
      return api.post(`/newbie/distribute`, data);
    },
    // put /newbie/distribute/{id}
    putNewbieDistribute (data, id) {
      return api.put(`/newbie/distribute/${id}`, data);
    },
    // get /newbie/target
    getNewbieTarget (data) {
      return api.get(`/newbie/target`, {
        params: data
      });
    },
    // get /newbie/target/{id}
    getNewbieTargetDetail (id) {
      return api.get(`/newbie/target/${id}`);
    },
    // post /newbie/target
    postNewbieTarget (data) {
      return api.post(`/newbie/target`, data);
    },
    // put /newbie/target/{id}
    putNewbieTarget (data, id) {
      return api.put(`/newbie/target/${id}`, data);
    },
    // delete /newbie/target/{id}
    deleteNewbieTarget (id) {
      return api.delete(`/newbie/target/${id}`);
    },
    // /common/coupontags
    getCommonCoupontags () {
      return api.get(`/common/coupontags`);
    },
    // get /common/customers
    getCommonCustomers () {
      return api.get(`/common/customers`);
    },
  }
}
