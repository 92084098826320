import store from "@/store";
import { authApi } from "@/api"
import { ElMessage } from 'element-plus';
import COS from "cos-js-sdk-v5";
import { ElLoading } from 'element-plus'

let loading = null

let loadingCount = 0;
let loadingTimer = null;

const startLoading = () => {
    store.dispatch("setLoading", true);
    loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(255, 255, 255, 0.7)',
    })
};

const endLoading = () => {
    store.dispatch("setLoading", false);
    if (loading) {
        loading.close()
    }
};

export const showLoading = () => {
    clearTimeout(loadingTimer);
    loadingTimer = setTimeout(() => {
        loadingCount = 1;
        hideLoading();
    }, 30000);
    if (loadingCount === 0) {
        startLoading();
    }
    loadingCount += 1;
};

export const hideLoading = () => {
    if (loadingCount <= 0) {
        return;
    }
    loadingCount -= 1;
    if (loadingCount === 0) {
        clearTimeout(loadingTimer);
        endLoading();
    }
};

export const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = (cookies[i] || '').trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export const setSessionParams = (key, data) => {
    sessionStorage.setItem(key, JSON.stringify(data))
}
export const getSessionParams = (key, isSelector) => {
    let data = null
    if (isSelector) {
        return data
    }
    try {
        const strData = sessionStorage.getItem(key)
        if (strData) {
            data = JSON.parse(strData)
        }
    } catch (error) {

    }
    return data
}

export const creatColorByNickName = (NickName) => {
    // nick name to code
    let id = 0;
    for (var i = 0; i < NickName.length; i++) {
        id += NickName.charCodeAt(i);
    }
    id = id % 100;
    const h = id * (360 / 100);
    const s = 80;
    const l = 30;
    return hslToHex(h, s, l);
};
export const hslToHex = (h, s, l) => {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n) => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color)
            .toString(16)
            .padStart(2, "0"); // convert to Hex and prefix "0" if needed
    };
    return `${f(0)}${f(8)}${f(4)}`;
};

export const unique = (arr) => {
    return Array.from(new Set(arr));
}
export const uniqueObjArray = (arr, key) => {
    const map = {
        'string': e => e[key],
        'function': e => key(e),
    }
    const fn = map[typeof key]
    const obj = arr.reduce((o, e) => (o[fn(e)] = e, o), {})
    return Object.values(obj)
}
export const download = (res, downloadRef) => {
    let fileName = ''
    const disposition = res.headers['content-disposition']
    if (disposition && disposition.indexOf('attachment') !== -1) {
        // 正規表現
        const filenameRegex = /filename[^;=\n]=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
            // matches[1]でとれる⇒ filename*=utf-8''201911%E3%83%87%E3%83%BC%E3%82%BF.csv;
            // 不要文字列を消して、デコードしてサーバからのファイル名を取得
            fileName = decodeURI(
                matches[1].replace(/['"]/g, '').replace('UTF-8', '')
            )
        }
    }
    const fileURL = window.URL.createObjectURL(
        new Blob([res.data], { type: 'application/octet-stream' })
    )
    console.log(fileURL)
    downloadRef.value.href = fileURL
    downloadRef.value.setAttribute('download', fileName)
    downloadRef.value.click()
    window.URL.revokeObjectURL(fileURL)
}

export const blobToText = (blob) => {
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
            fileReader.abort();
            reject();
        };

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.readAsText(blob);
    });
}


export const setTitle = (title) => {
    store.dispatch('setPageTitle', title)
    document.title = title
}

export const uuid = () => {
    let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
    for (let i = 0, len = chars.length; i < len; i++) {
        switch (chars[i]) {
            case "x":
                chars[i] = Math.floor(Math.random() * 16).toString(16);
                break;
            case "y":
                chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
                break;
        }
    }
    return chars.join("");
}

export const uploadFile = async (code, fileObject) => {
    return new Promise(async (resolve, reject) => {
        let cosInfo = null
        await authApi.getCommonUpoad(code).then((res) => {
            cosInfo = res.data
        }).catch((err) => {
            reject(err)
        })
        if (cosInfo) {
            const cosObj = new COS({
                // getAuthorization 必选参数
                getAuthorization: function (options, callback) {
                    const {
                        tmpSecretId,
                        tmpSecretKey,
                        sessionToken,
                        startTime,
                        expiredTime,
                    } = cosInfo;
                    callback({
                        TmpSecretId: tmpSecretId,
                        TmpSecretKey: tmpSecretKey,
                        SecurityToken: sessionToken,
                        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                        StartTime: startTime, // 时间戳，单位秒，如：1580000000
                        ExpiredTime: expiredTime, // 时间戳，单位秒，如：1580000000
                    });
                },
            });
            cosObj.uploadFile(
                {
                    Bucket: cosInfo.bucket /* 填入您自己的存储桶，必须字段 */,
                    Region:
                        cosInfo
                            .region /* 存储桶所在地域，例如ap-beijing，必须字段 */,
                    Key: `${cosInfo.folder
                        }${uuid()}` /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
                    Body: fileObject /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */,
                    SliceSize:
                        1024 *
                        1024 *
                        5 /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */,
                    onTaskReady: function (taskId) {
                        /* 非必须 */
                        showLoading();
                        console.log(taskId);
                    },
                    onProgress: function (progressData) {
                        /* 非必须 */
                        console.log(JSON.stringify(progressData));
                    },
                    onFileFinish: function (err, data, options) {
                        /* 非必须 */
                        hideLoading();
                        if (err) {
                            ElMessage.error({
                                message: 'アップロードに失敗しました',
                                'custom-class': "common-message-error"
                            });
                            reject(err);
                        } else {
                            // console.warn(data);
                            resolve({ data, options });
                        }
                        console.log(options.Key + "上传" + (err ? "失败" : "完成"));
                    },
                },
                function (err, data) {
                    console.log(err || data);
                }
            );
        } else {
            ElMessage.error({
                message: '获取上传信息失败',
                'custom-class': "common-message-error"
            });
        }
    })
}

export const startEndTimeCheck = (rule, value, callback, start, end) => {
    if (start && end) {
        if (new Date(start).getTime() >= new Date(end).getTime()) {
            callback(new Error('开始时间或结束时间的设置有误'))
        } else {
            callback()
        }
    } else {
        callback()
    }

}

export const getApplyStatus = (applyStatus_) => {
    const applyStatusStrArr = applyStatus_?.split(',') || []
    return {
        applyStatus: applyStatusStrArr[0] || null,
        applyPassStatus: applyStatusStrArr[1] || null,
    }
}