import { createStore } from "vuex";
import { authApi } from "@/api";

let commonCosUrlPromise = null

export default createStore({
  state: {
    cosurl: null,
    loadingShow: false,
    adminInfo: null,
  },
  getters: {
    cosurl: (state) => state.cosurl,
    loadingShow: (state) => state.loadingShow,
    adminInfo: (state) => state.adminInfo,
  },
  mutations: {
    setCosurl (state) {
      if (commonCosUrlPromise === null && state.cosurl === null) {
        commonCosUrlPromise = authApi.getCommonCosUrl().then(res => {
          state.cosurl = res.data;
        }).finally(() => {
          commonCosUrlPromise = null
        })
      }
    },
    setLoading (state, data) {
      state.loadingShow = data;
    },
    setAdminInfo (state) {
      authApi.getAdmin().then((res) => {
        state.adminInfo = res.data
      }).catch(()=>{
        state.adminInfo = null
      })
    },
    deleteAdminInfo  (state) {
      state.adminInfo = null;
    },
  },
  actions: {
    setCosurl ({ commit }) {
      commit("setCosurl");
    },
    setLoading ({ commit }, data) {
      commit("setLoading", data);
    },
    setAdminInfo ({ commit }) {
      commit("setAdminInfo");
    },
    deleteAdminInfo ({ commit }) {
      commit("deleteAdminInfo");
    },
  },
  modules: {},
});
