<template>
  <el-image v-if="imageSrc" :src="imageSrc" fit="contain" />
</template>
<script>
import { reactive, ref } from '@vue/reactivity'
import { authApi } from '@/api'
import store from '@/store'
import { computed, onMounted } from '@vue/runtime-core'

export default {
  props: {
    src: {},
  },
  setup(props) {
    const cosurl = computed(() => {
      return store.getters.cosurl
    })
    const imageSrc = computed(() => {
      if (cosurl.value && props.src) {
        if (props.src.indexOf('blob:') !== -1) {
          return props.src
        }
        return `${cosurl.value}${props.src}`
      }
      return null
    })
    const urlInit = () => {
      if (!cosurl.value) {
        store.dispatch('setCosurl')
      }
    }
    onMounted(() => {
      urlInit()
    })
    return {
      cosurl,
      imageSrc,
    }
  },
}
</script>
<style></style>
